<template>
  <scroll-container>
    <FeatureManagement />
  </scroll-container>
</template>

<script>
import FeatureManagement from '../bodies/FeatureManagement.vue'

export default {
  name: 'SubscriptionManagement',
  data() {
    return {
      loading: 0
    }
  },
  computed: {},
  methods: {},
  mounted() {},
  components: {
    FeatureManagement
  }
}
</script>
